import {
  Grid,
  List,
  ListItemText,
  Button,
  Stack,
  Link,
} from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../../styles/theme";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { SubscribeTf, FooterTitle } from "../../styles/footer";
import SendIcon from "@mui/icons-material/Send";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import { BannerShopButton } from "../../styles/banner";

export default function Footer() {
  return (
    <Box
      sx={{
        background: Colors.secondary,
        color: Colors.black,
        p: { xs: 4, md: 10 },
        pt: 12,
        pb: 12,
        fontSize: { xs: '12px', md: '14px' }
      }}
    >
      <Grid container spacing={10} justifyContent="center">
        <Grid item md={6} lg={4}>
          <FooterTitle variant="body1">About us</FooterTitle>
          <Stack spacing={1}>
          <BannerShopButton startIcon={<LocationOnIcon sx={{ color: Colors.white }} />} color="primary"><Link underline="none" sx={{ color: Colors.white }} href="https://goo.gl/maps/jd9tc8bM2SnwHt7VA" target="_blank">LOCATION</Link> </BannerShopButton>

          <BannerShopButton startIcon={<EmailIcon sx={{ color: Colors.white }} />} color="primary"><Link sx={{ color: Colors.white }} href="mailto:nebawale1111@gmail.com" target="_blank">masalmotors@gmail.com</Link> </BannerShopButton>

          </Stack>
          <Box
            sx={{
              mt: 4,
              color: Colors.black,
            }}
          >
           <Link href="https://www.facebook.com/" target="_blank"><FacebookIcon sx={{ color: Colors.white, mr: 1 }} /></Link> 
           <Link href="https://www.twitter.com/" target="_blank"><TwitterIcon sx={{color: Colors.black, mr: 1 }} /></Link> 
           <Link href="https://www.instagram.com/" target="_blank"><InstagramIcon sx={{color: Colors.white, mr: 1 }} /></Link> 
            
          </Box>
        </Grid>
        <Grid item md={6} lg={3}>
          <FooterTitle id="aboutus" variant="body1">information</FooterTitle>
          <List>
          <ListItemText><Link href="/" underline="none">Home</Link></ListItemText>
        <ListItemText><Link href="#cars" underline="none">Automobiles</Link></ListItemText>
        <ListItemText><Link href="#fiber" underline="none">Fiber</Link></ListItemText>
        <ListItemText><Link href="#exports"underline="none">Masel Exports</Link></ListItemText>
        <ListItemText><Link href="#aboutus" underline="none">About-Us</Link></ListItemText>
          </List>
        </Grid>
        <Grid item md={6} lg={4}>
          <FooterTitle variant="body1">newsletter</FooterTitle>
          <Stack>
            <SubscribeTf
              color="primary"
              label="Email address"
              variant="standard"
            />
            <Button
              startIcon={<SendIcon sx={{ color: Colors.white }} />}
              sx={{ mt: 4, mb: 4 }}
              variant="contained"
            >
              Subscribe
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', color: '#433E0E' }}>
      <Link href="https://linktr.ee/banetech9?utm_source=linktree_profile_share&ltsid=646b7abb-4ed8-48b2-a490-c988779eb745" target="_blank"> 
      <BannerShopButton fullWidth color="primary">
      Powered By Bane Technology
      </BannerShopButton>
</Link>
      </Box>
    </Box>
  );
}
