import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'

import './Navbar.css'

const Navbar = () => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const closeMenu = () => setClick(false)

    return (
        <div className='header'>
            <nav className='navbar'>
                <a href='/' className='logo'>
                    <img src="/images/banner/logo192.png" alt='logo' />
                </a>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#433E0E' }} />)
                        : (<FaBars size={30} style={{ color: '#433E0E' }} />)}

                </div>
                <div className={click ? "nav-menu active" : "nav-menu"}>
                    <div className='nav-item'>
                        <a href='/' onClick={closeMenu}>Home</a>
                    </div>
                    <div className='nav-item'>
                        <a href="#cars" onClick={closeMenu}>Automobiles</a>
                    </div>
                    <div className='nav-item'>
                        <a href="#fiber" onClick={closeMenu}>Fiber Glass</a>
                    </div>
                    <div className='nav-item'>
                        <a href='#exports' onClick={closeMenu}>Masel Exports</a>
                    </div>
                    <div className='nav-item'>
                        <a href='#aboutus' onClick={closeMenu}>About Us</a>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar