export const products = [
    {
        id: 1,
        name: "TOYOTA C-HR",
        category: "Cars",
        description: "Engine Type Gas",
        desc1: "Front wheel drive Total Seating-5",
        desc2: " Fuel Tank 50Lit",
        desc3: " Horsepower	144 hp@6,100rpm",
        desc4: " 6 Total speakers and USB connection with keyless iginition",
        desc5: "Rear view mirror", 
        desc6: "Pre and Post Collision saftey system",
        desc7: "Continuously variable-speed",
        desc8: "automatic transmission",
        desc9: "Four-wheel independent suspension",
        desc10: "Front and rear stabilizer bar with a Maximum Cargo capacity of 600cm and a maximum payload of 375 kg",
        image: "/images/products/chr-1.png",
        availability: "3 in Grey and White"
    },
    {
        id: 2,
        name: "GRAND I 10",
        category: "Cars",
        description: "Engine Type - 1.2 Kappa Dual VTVT ",
        desc1: "Front wheel drive Total Seating - 5",
        desc2: "Fuel Tank - 43Lit",
        desc3: "Horsepower - 83 bhp@6000 rpm ",
        desc4: "Length - 3,765mm and Width - 1,660mm",
        desc5: "Front And Rear Brake Type - Disk & Drum respectivley", 
        desc6: "Pre and Post Collision saftey system",
        desc7: "Mileage - 18.90 KM/L",
        desc8: "Transmision - 5 Gears and Single Clutch",
        desc9: "Suspensions - Mcpherson Sturt & Coupled Torsion Beam Axle",
        availability: "5 Available All in Dark-Grey",
        image: "/images/products/i10-4.png",
    },
    {
        id: 3,
        name: "TOYOTA RAIZE 2022",
        category: "Cars",
        description: "Engine Type - 1.0L, 3-cylinder gasoline in-line, 12 valve DOCH, DVVT ",
        desc1: "Front wheel drive Total Seating - 5",
        desc2: "Fuel Tank - 36Lit",
        desc3: "Horsepower - 96HP @ 6,000RPM ",
        desc4: "Length - 4,030mm and Width - 1,710mm",
        desc5: "Tourque - 140Nm @ 4,000RPM", 
        desc6: "Side View Mirrors - Power Adjustable, Auto Retractable",
        desc7: "Saftey - Blind Spot Monitor, 4 Parking Sensors, Reverse Camera, 6SRS airbags, Child Locks and Child Restraint System, ABS,  ",
        desc8: "Transmision - 7 speed CVT",
        desc9: "Suspensions - Mcpherson Sturt & Torsion Beam",
        desc10: "Anti-theft System - Immobilizer and Alarm",
        desc11: "Interior - Smart Entry, Push Start, 8-inch touchscreen display, Bluetooth, 3-USB and 6 Speakers", 
        availability: "1 Available in White",
        image: "/images/products/raize-2.png",
    },
    {
        id: 4,
        name: "TOYOTA 2021 CHR EV",
        category: "Cars",
        description: "Engine Type - Permanent Magnet Synchronous Motor And Electric ",
        desc1: "Front wheel drive Total Seating - 5",
        desc2: "Range - 400 km",
        desc3: "DC Charge 50 kw/50 min --- AC Charge 12.8kW/6.5 hours ",
        desc4: "Length - 4,405mm, Width - 1795mm and Height - 1575mm",
        desc5: "Tourque - 300nm MAX-Speed - 160km/h", 
        desc6: "Side View Mirrors - Power Adjustable, Auto Retractable",
        desc7: "Saftey - Blind Spot Monitor, 4 Parking Sensors, Reverse Camera, 6SRS airbags, Child Locks and Child Restraint System, ABS,  ",
        desc8: "Transmision - 7 speed CVT",
        desc9: "Suspensions - Mcpherson Sturt & Torsion Beam",
        desc10: "Anti-theft System - Immobilizer and Alarm",
        desc11: "Interior - Smart Entry, Push Start, 8-inch touchscreen display, Bluetooth, 3-USB and 6 Speakers", 
        availability: "1 Available in White",
        image: "/images/products/CHR_EV4.png",

    },
    {
        id: 5,
        name: "HONDA MNV EV",
        category: "Cars",
        description: "Engine Type - Electric",
        desc1: "Total Seating - 5",
        desc2: "Power - 150KW",
        desc3: "Voltage - 48V & Energy-Capacity - 61.3kwh",
        desc4: "Length - 4,324mm and Width - 1,785mm",
        desc5: "MAX Power, Torque & Speed - 120kw, 280n.M and 140km/h respectively", 
        desc6: "Side View Mirrors - Power Adjustable, Auto Retractable",
        desc7: "Saftey - 4 Parking Sensors, Rear View Mirror, 6-SRS airbags, Child Locks and Child Restraint System,",
        desc8: "Battery Type - Ternary Lithium Battery",
        desc9: "Origin - China",
        desc10: "Anti-theft System - Immobilizer and Alarm",
        desc11: "Interior - Keyless Entry, Push Start, 8-inch touchscreen display, Bluetooth, 3-USB and 6 Speakers", 
        availability: "1 Available in White",        
        image: "/images/products/HONDA MNV EV-1.png",
    }
]