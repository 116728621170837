export const fiber = [
        {
            id: 7,
            name: "Fiber Glass ",
            category: "Cars",
            description: "This are tick and hard polycarbonate made for Doors and Doors we import them for this specific use and are highly durable and resistant to any kind of tempreture and condition it is one of the upcoming products in our countries",
            image: "/images/products/Door1.png"
        },
        {
            id: 8,
            name: "Line Fiber Glass",
            category: "Cars",
            price: 689.99,
            description: "This are tick and hard polycarbonate made for Doors and Doors we import them for this specific use and are highly durable and resistant to any kind of tempreture and condition it is one of the upcoming products in our countries",
            image: "/images/products/Door2.png"
        },
        {
            id: 9,
            name: "Polycarbonate For Roofs",
            category: "Fiber",
            price: 99.99,
            description: "This are tick and hard polycarbonate made for Doors and Doors we import them for this specific use and are highly durable and resistant to any kind of tempreture and condition it is one of the upcoming products in our countries",
            image: "/images/products/Door3.png"
        },
        {
            id: 10,
            name: "Thick PolyCarbonate",
            category: "Fiber",
            price: 89.99,
            description: "This are tick and hard polycarbonate made for Doors and Doors we import them for this specific use and are highly durable and resistant to any kind of tempreture and condition it is one of the upcoming products in our countries",
            image: "/images/products/Door4.png"
        },
        {
            id: 11,
            name: "Opaque Fiber Glass",
            category: "Fiber",
            price: 89.99,
            description: "This are tick and hard polycarbonate made for Doors and Doors we import them for this specific use and are highly durable and resistant to any kind of tempreture and condition it is one of the upcoming products in our countries",
            image: "/images/products/Door5.png"
        },
        {
            id: 12,
            name: "Transparent Fiber Glass",
            category: "Fiber",
            price: 89.99,
            description: "This are tick and hard polycarbonate made for Doors and Doors we import them for this specific use and are highly durable and resistant to any kind of tempreture and condition it is one of the upcoming products in our countries",
            image: "/images/products/Door6.png"
        },    
]